import React, { useEffect } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { PopUpBox } from './PopupBox';
import { Button } from '../shared/Button';
import { BUTTON, HEADING } from '../../constants';


const CameraScanner = ({ handleError, handleScan, setIsCamScannerOpen, isCamScannerOpen }) => {
  useEffect(() => {
    if (!isCamScannerOpen) {
      handleScan(''); // Clear result when scanner closes
    }
  }, [isCamScannerOpen]);
    return (
    <div>
      <PopUpBox openPopUpBox={isCamScannerOpen}>
        <div>
          <h5 className="bg-secondary p-2 text-light mb-2">{HEADING.SCAN_BARCODE}</h5>
          {isCamScannerOpen && (
            <BarcodeScannerComponent
              onUpdate={(err, result) => {
                if (result) {
                  handleScan(result.text);
                  setIsCamScannerOpen(false);
                }
                if (err) handleError(err);
              }}
              delay={400}
            />
          )}
          <Button
            className="border-0 mt-2 blue-btn d-flex align-items-center justify-content-center py-2 px-3"
            text={BUTTON.CLOSE}
            clickHandler={() => setIsCamScannerOpen(false)}
          />
        </div>
      </PopUpBox>
    </div>
  );
};

export default CameraScanner;
