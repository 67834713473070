import { popup } from './popup';
import { POPUP_TYPE, POPUP_MSG } from '../constants';
export const idb =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

export const createDataBaseInIndexedDb = () => {
  if (!idb) {
    popup(POPUP_TYPE.ERR, POPUP_MSG.INDEX_DB_NOT_SUPPORTED);
    return;
  }
  const request = idb.open('shakaWear-db', 1);
  request.onerror = function (event) {
    console.error('IndexedDB error:', event);
  };
  request.onupgradeneeded = function () {
    const db = request.result;
    if (!db.objectStoreNames.contains('ShipmentData')) {
      const objectStore = db.createObjectStore('ShipmentData', { keyPath: 'id' });
      objectStore.createIndex('shipment_id', 'shipment_id', { unique: true });
    }
  };
};

export const transactionIndexDB = ({ action, collection, item }) => {
  const request = idb.open('shakaWear-db', 1);
  request.onerror = function (event) {
    console.error('IndexedDB error during transaction:', event);
  };
  request.onupgradeneeded = function () {
    const db = request.result;
    if (!db.objectStoreNames.contains(collection)) {
      db.createObjectStore(collection, { keyPath: 'id' });
    }
  };
  request.onsuccess = function () {
    const db = request.result;
    if (!db.objectStoreNames.contains(collection)) {
      console.error(`Object store "${collection}" not found even after creation attempt.`);
      return;
    }
    const transaction = db.transaction(collection, 'readwrite');
    const store = transaction.objectStore(collection);
    let query;
    if (action === 'create') {
      query = store.add(item);
    } else if (action === 'update') {
      query = store.put(item);
    } else if (action === 'delete') {
      query = store.delete(item.id);
    } else {
      console.error(`Invalid action "${action}" provided.`);
      return;
    }
    query.onerror = function (event) {
      console.error(`Error during transaction (${action}) on "${collection}":`, event);
    };
  };
};
