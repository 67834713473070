import React from 'react';
export const PopUpBox = ({ openPopUpBox, children, tabIndex = -1 }) => {
  return (
    <div
      className={`popup-position modal h-100 custom-modal-doc-cato fade  ${openPopUpBox ? 'show' : ''}`}
      aria-hidden={!openPopUpBox}
      tabIndex={tabIndex}
      style={{ display: openPopUpBox ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered w-100 h-75 custom-width-modal">
        <div className="modal-content overflow-y-scroll p-3 ">
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};
