import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SearchBar, TitleBar, DropDown, PaginatedTable } from '../../common';
import { BUTTON, POPUP_TYPE, ROUTES, PALLETING_COLUMN, TITLES } from '../../../constants';
import { getPelletList } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { popup, pelletListCompiler } from '../../../utils';
import { useDebounce } from '../../../hooks';
import { Button } from '../../shared';

export const PelletList = () => {
  const [pelletData, setPelletData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort, setSort] = useState(PALLETING_COLUMN[6].name);
  const [sortingArray, setSortingArray] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();

  const fetchPelletList = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    getPelletList({ data: params })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.records?.length > 0) {
          setPelletData(response.data.records);
          setPaginationData(response.data.params);
          if (!sortingArray.includes(sort)) {
            setSortingArray(response.data.pallet_process_state.map((item) => item.toUpperCase()));
          }
        } else {
          setPelletData([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 100);

  const getPelletListData = (additionalParams = {}) => {
    const params = {
      page_number: pageNumber,
      search: searchTerm
        ? {
            name: searchTerm,
            container_id: searchTerm,
          }
        : {},
      sort: sortingArray.includes(sort)
        ? { selection: { pallet_process_state: sort.toLowerCase() } }
        : {},
      ...additionalParams,
    };
    fetchPelletList(params);
  };

  useEffect(() => {
    if (search) {
      setPageNumber(1);
      // setSearch(false);
      getPelletListData({ page_number: 1 });
    }
  }, [search]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getPelletListData();
  }, [pageNumber]);

  useEffect(() => {
    if (sortingArray.length > 0) {
      setPageNumber(1);
      getPelletListData({ page_number: 1 });
    }
  }, [sort]);

  const resetFilter = () => {
    setSort(PALLETING_COLUMN[6].name);
    setPageNumber(1);
    getPelletListData({ page_number: 1 });
  };

  useEffect(() => {
    if (!searchTerm && search) {
      setPageNumber(1);
      setSearch(false);
      getPelletListData({ page_number: 1 });
    }
  }, [searchTerm]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.PELLETING, route: ROUTES.PELLETING }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar
          search={search}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          setSearch={setSearch}
          onDefaultSearch={getPelletListData}
        />
        <div className="w-50 mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray} value={sort} setValue={setSort}></DropDown>
          {sortingArray.includes(sort) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(pelletData) && pelletData?.length > 0 ? (
        <PaginatedTable
          columns={PALLETING_COLUMN}
          initialData={pelletData}
          colFilter={false}
          to={ROUTES.PELLETING}
          compiler={{ use: true, tool: pelletListCompiler }}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          paginationData={paginationData}
        />
      ) : (
        <div>
          <h4 className="px-1 text-center py-5">No Records Found</h4>
        </div>
      )}
    </>
  );
};
