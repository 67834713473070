import React from 'react';
import { EditIcon } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants';

export const ActionBoxMultiStep = ({ multi_location_pick, finalize_picked, move_id, name, state }) => {
  const navigate = useNavigate();
  return (
    <div className="td-num">
      {multi_location_pick && state !== 'done' && !finalize_picked ? (
        <EditIcon
          onClick={(e) => {
            e.stopPropagation();
            navigate(ROUTES.MULTI_STEP_PICKUP_CREATE, { state: { move_id, name } });
          }}
        />
      ) : (
        '-'
      )}
    </div>
  );
};
