import React from 'react';
import { EditIcon, ScanIcon } from '../../../assets/icons';

export const ActionBox = ({
  blpName,
  scan_picked,
  done_quantity,
  reserve_quantity,
  move_line_id,
  is_la_delivery,
  setPopUp,
  setPopUpData,
  create_delivery_package,
  isMultiStep
}) => {
  return (
    <div className="td-num">
      {(blpName === 'N/A' && create_delivery_package===false) ? (
        is_la_delivery===false && isMultiStep===false ?<EditIcon
          onClick={(e) => {
            e.stopPropagation();
            setPopUp(1);
            setPopUpData({ done_quantity, blpName, scan_picked, reserve_quantity, move_line_id });
          }}
        />:'-'
      ) : scan_picked ? (
        <ScanIcon
          onClick={(e) => {
            e.stopPropagation();
            setPopUp(2);
            setPopUpData({ blpName, scan_picked });
          }}
        />
      ) : (
        '-'
      )}
    </div>
  );
};
