import React, { useEffect, useState } from 'react';
import { DropDown, PaginatedTable, Scanner, SearchBar, TitleBar } from '../../common';
import { ROUTES, TRANSFER_COLUMN, TITLES, POPUP_TYPE, BUTTON } from '../../../constants';
import { transferDataCompiler } from '../../../utils/data_compiler/transferCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { createTransferWT, warehouseTransfer, warehouseTransferByScan } from '../../../service';
import { Button } from '../../shared';
import { ScanIcon } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';

export const Transfers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transfersData, setTransfersData] = useState([]);
  const [initialTransfersData, setInitialTransfersData] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [clearEnabled, setClearEnabled] = useState(false);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(TRANSFER_COLUMN[6].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  const fetchTransfers = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    warehouseTransfer({ data: params })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.records?.length > 0) {
          setTransfersData(response?.data?.records);
          setInitialTransfersData(response?.data?.records);
          setPaginationData(response?.data?.params);
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(response.data.state.map((item) => item.toUpperCase()));
          }
        } else {
          setTransfersData([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 100);

  // Wrapper function for getting the transfers list with dynamic parameters
  const getTransfersList = (additionalParams = {}) => {
    const params = {
      page_number: pageNumber,
      search: searchTerm
        ? {
            name: searchTerm,
            origin: searchTerm,
          }
        : {},
      sort: sortingArray1.includes(sort1) ? { selection: { state: sort1.toLowerCase() } } : {},
      ...additionalParams,
    };
    fetchTransfers(params);
  };

  const startScanning = () => {
    setScanning((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  const getTransfersByScan = useDebounce((scanData) => {
    warehouseTransferByScan({
      data: { package_name: `${scanData}`, page: pageNumber },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.message.includes('no')) popup(POPUP_TYPE.ERR, response.message);
        else popup(POPUP_TYPE.SUCCESS, response.message);
        setTransfersData(response.data.records);
        setPaginationData(response.data.params);
        setScanning(false);

        setClearEnabled(true);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleCreateTransfer = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    createTransferWT({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length) {
          setScanning(false);
          popup(POPUP_TYPE.SUCCESS, response.message);
          navigate(`${ROUTES.TRANSFER_DETAIL}/${response.data[0].name.replaceAll('/', '_')}`, {
            state: response.data[0],
          });
        } else popup(POPUP_TYPE.ERR, response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  // Trigger a search and reset page number
  useEffect(() => {
    if (search) {
      setPageNumber(1);
      // setSearch(false);
      getTransfersList({ page_number: 1 });
    }
  }, [search]);

  // Fetch data on page change
  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getTransfersList();
  }, [pageNumber]);

  // Handle sorting and reset page number
  useEffect(() => {
    if (sortingArray1.length > 0) {
      setPageNumber(1);
      getTransfersList({ page_number: 1 });
    }
  }, [sort1]);

  // Reset filters
  const resetFilter = () => {
    setSort1(TRANSFER_COLUMN[6].name);
    setPageNumber(1);
    getTransfersList({ page_number: 1 });
  };

  // Clear search and reset the list
  const handleClear = () => {
    setSearchTerm('');
    setClearEnabled(false);
    setTransfersData(initialTransfersData);
    setPageNumber(1);
    getTransfersList({ page_number: 1 });
  };

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      getTransfersByScan(scanData);
    }
  }, [scanData]);

  useEffect(() => {
    if (!searchTerm && search) {
      setPageNumber(1);
      setSearch(false);
      getTransfersList({ page_number: 1 });
    }
  }, [searchTerm]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.TRANSFER, route: ROUTES.TRANSFERS }]} />
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-start gap-3 w-m-100">
          <SearchBar
            search={search}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            setSearch={setSearch}
            onDefaultSearch={getTransfersList}
          />
          <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
            <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
            {sortingArray1?.includes(sort1) && (
              <Button
                clickHandler={resetFilter}
                className="blue-btn d-flex align-items-center justify-content-center px-3">
                {BUTTON.CLEAR_FILTERS}
              </Button>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end ">
          {scanning && (
            <Scanner
              setScanData={setScanData}
              isCamScannerOpen={isCamScannerOpen}
              setIsCamScannerOpen={setIsCamScannerOpen}
            />
          )}
          {!clearEnabled && (
            <Button
              className={`white-btn d-flex align-items-center justify-content-center me-2 px-3 mb-3`}
              clickHandler={startScanning}>
              {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
            </Button>
          )}

          {clearEnabled && (
            <Button
              className="white-btn d-flex align-items-center justify-content-center px-3 mb-3 me-2"
              clickHandler={handleClear}>
              {BUTTON.CLEAR}
            </Button>
          )}
          <Button
            className="white-btn d-flex align-items-center justify-content-center px-3 mb-3"
            clickHandler={handleCreateTransfer}>
            {BUTTON.CREATE_TRANSFER}
          </Button>
        </div>
      </div>
      {Array.isArray(transfersData) && transfersData.length > 0 ? (
        <PaginatedTable
          columns={TRANSFER_COLUMN}
          initialData={transfersData}
          to={ROUTES.TRANSFER_DETAIL}
          compiler={{ use: true, tool: transferDataCompiler }}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          paginationData={paginationData}
        />
      ) : (
        <div>
          <h4 className="px-1 text-center py-5">No Records Found</h4>
        </div>
      )}
    </>
  );
};
