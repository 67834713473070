import React from 'react';
import { EditIcon, DeleteRedIcon } from '../../../assets/icons';

export const ActionBoxInnerMultiStep = ({
  move_id,
  move_line_id,
  quantity,
  location_name,
  location_id,
  setPopUp,
  setPopUpData,
}) => {
  return (
    <div className="d-flex justify-content-evenly h-100 align-items-center gap-3">
      {/* Edit Icon */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setPopUp(1);
          setPopUpData({ move_id, move_line_id, quantity, location_name, location_id });
        }}
        className="w-50 h-100 m-0 d-flex align-items-center justify-content-center">
        <EditIcon />
      </div>

      {/* Delete Icon */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setPopUp(2);
          setPopUpData({ move_id, move_line_id, quantity, location_name, location_id });
        }}
        className="w-50 h-100 m-0 d-flex align-items-center justify-content-center ">
        <DeleteRedIcon />
      </div>
    </div>
  );
};
