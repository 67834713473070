import React, { useEffect, useState } from 'react';
import { DropDown, PaginatedTable, Scanner, SearchBar, TitleBar } from '../../common';
import { ROUTES, TITLES, POPUP_TYPE, BUTTON } from '../../../constants';
import { DELIVERY_COLUMN } from '../../../constants';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { outgoingScanDelivery, warehouseDelivery } from '../../../service';
import { deliveryDataCompiler } from '../../../utils/data_compiler/deliveryDataCompiler';
import { ScanIcon } from '../../../assets/icons';
import { Button } from '../../shared';

export const Deliveries = () => {
  const dispatch = useDispatch();
  const [deliveryData, setDeliveryData] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(DELIVERY_COLUMN[5].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  // API Call to fetch delivery list
  const getDeliveryList = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    warehouseDelivery({ data: params })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.records?.length > 0) {
          setDeliveryData(response?.data?.records);
          setPaginationData(response?.data?.params);
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(response.data.state.map((item) => item.toUpperCase()));
          }
        } else {
          setDeliveryData([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 100);

  // Wrapper function for getting the delivery list with dynamic parameters
  const fetchDeliveryData = (additionalParams = {}) => {
    const params = {
      page_number: pageNumber,
      search: searchTerm
        ? {
            name: searchTerm,
            origin: searchTerm,
          }
        : {},
      sort: sortingArray1.includes(sort1) ? { selection: { state: sort1.toLowerCase() } } : {},
      ...additionalParams,
    };
    getDeliveryList(params);
  };

  // Start scanning
  const startScanning = () => {
    setScanning((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    if (isCameraScanBool && !isCamScannerOpen) {
      setScanning(false);
    }
  }, [isCamScannerOpen]);

  // Handle scan result
  const handleOutgoingDeliveryScan = useDebounce((scanData) => {
    dispatch(setLoaderVisibility(true));
    outgoingScanDelivery({
      data: { sale_order_ref: scanData },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        fetchDeliveryData(); // Fetch updated delivery list
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
    setScanning(false);
    setScanData('');
  }, 1000);

  // Fetch data on page change
  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    fetchDeliveryData();
  }, [pageNumber]);

  // Search functionality: reset page number and fetch data
  useEffect(() => {
    if (search) {
      setPageNumber(1);
      // setSearch(false);
      fetchDeliveryData({ page_number: 1 });
    }
  }, [search]);

  // Handle sorting change: reset page number and fetch data
  useEffect(() => {
    if (sortingArray1.length > 0) {
      setPageNumber(1);
      fetchDeliveryData({ page_number: 1 });
    }
  }, [sort1]);

  // Reset filters
  const resetFilter = () => {
    setSort1(DELIVERY_COLUMN[5].name);
    setPageNumber(1);
    fetchDeliveryData({ page_number: 1 });
  };

  // Handling scan data changes
  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      handleOutgoingDeliveryScan(scanData);
    }
  }, [scanData]);

  useEffect(() => {
    if (!searchTerm && search) {
      setPageNumber(1);
      setSearch(false);
      getDeliveryList({ page_number: 1 });
    }
  }, [searchTerm]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.DELIVERY, route: ROUTES.DELIVERIES }]} />
      <div className="d-flex align-items-center justify-content-between flex-test">
        <div className="d-flex align-items-center justify-content-start gap-3 w-100">
          <SearchBar
            search={search}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            setSearch={setSearch}
            onDefaultSearch={fetchDeliveryData}
          />
          <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
            <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
            {sortingArray1.includes(sort1) && (
              <Button
                clickHandler={resetFilter}
                className="blue-btn d-flex align-items-center justify-content-center px-3">
                {BUTTON.CLEAR_FILTERS}
              </Button>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          {scanning && (
            <Scanner
              setScanData={setScanData}
              isCamScannerOpen={isCamScannerOpen}
              setIsCamScannerOpen={setIsCamScannerOpen}
            />
          )}
          <Button
            className={`white-btn d-flex align-items-center justify-content-center px-3 mb-3`}
            clickHandler={startScanning}>
            {scanning ? BUTTON.STOP_SCAN : BUTTON.OUTGOING_DELIVERY_SCAN} <ScanIcon />
          </Button>
        </div>
      </div>
      {Array.isArray(deliveryData) && deliveryData?.length > 0 ? (
        <PaginatedTable
          columns={DELIVERY_COLUMN}
          initialData={deliveryData}
          to={ROUTES.DELIVERY_DETAIL}
          compiler={{ use: true, tool: deliveryDataCompiler }}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          paginationData={paginationData}
        />
      ) : (
        <div>
          <h4 className="px-1 text-center py-5">No Records Found</h4>
        </div>
      )}
    </>
  );
};
