export const deliveryDataCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let deliveryData = {
      url: obj.name.replaceAll('/', '_'),
      reference: obj.name || 'N/A',
      from: obj.location_id?.display_name || 'N/A',
      to: obj.location_dest_id?.display_name || 'N/A',
      scheduled_date: obj.scheduled_date.split(' ')[0],
      source_document: obj.origin || 'N/A',
      status: obj.state.toUpperCase(),
      data: obj,
    };
    finalData.push(deliveryData);
  });
  return finalData;
};

export function deliveryDetailBox(data) {
  let detailBoxData = [
    { name: 'Contact', col: 1, data: data?.partner_id?.name || data?.user_id?.name || 'N/A' },
    { name: 'Source Location', col: 1, data: data?.location_id?.display_name || 'N/A' },
    { name: 'Destination Location', col: 1, data: data?.location_dest_id?.display_name || 'N/A' },
    { name: 'Packing Preference', col: 1, data: data?.packing_preference || 'N/A' },
    { name: 'Scheduled Date', col: 2, data: data?.scheduled_date || 'N/A' },
    { name: 'Deadline', col: 2, data: data?.date_deadline || 'N/A' },
    { name: 'Source Document', col: 2, data: data?.origin || 'N/A' },
    { name: 'Status', col: 2, data: data?.state?.toUpperCase() || 'N/A' },
  ];
  return detailBoxData;
}
export const innerDeliveryOperationsCompiler = (data) => {
  let finalData = [];
  data?.move_ids?.map((obj) => {
    let deliveryOperationData = {
      move_id: obj?.id,
      is_check_picking: data?.is_check_picking,
      is_picking_checked: data?.is_picking_checked,
      check_move: obj?.check_move,
      checked: obj?.checked,
      finalize_picked: data?.finalize_picked,
      product: obj.product_id?.display_name,
      demand: obj.product_uom_qty,
      quantity: obj.quantity,
      unit: obj.product_uom?.name,
      state: data?.state,
      is_start_picking_pressed: data?.is_start_picking_pressed,
    };
    finalData.push(deliveryOperationData);
  });
  return finalData;
};

export const innerDeliveryLAOperationsCompiler = (data) => {
  let finalData = [];
  data?.move_ids?.map((obj) => {
    let deliveryOperationData = {
      is_check_picking: data?.is_check_picking,
      is_picking_checked: data?.is_picking_checked,
      check_move: obj?.check_move,
      checked: obj?.checked,
      finalize_picked: data?.finalize_picked,
      product: obj.product_id?.display_name,
      state: data?.state,
      is_start_picking_pressed: data?.is_start_picking_pressed,
      demand: obj.product_uom_qty,
      quantity: obj.quantity,
      unit: obj.product_uom?.name,
      total_picked_quantity: obj?.total_picked_qty,
      picked_quantity: obj?.picked_qty,
      scanned_boxes: obj?.scan_picked_qty,
      move_id: obj?.id,
    };
    finalData.push(deliveryOperationData);
  });
  return finalData;
};

export const innerDeliveryDetailedOperationCompiler = (data) => {
  let finalData = [];
  if (data?.move_ids?.length > 0) {
    data?.move_ids?.forEach((obj) => {
      if (obj.move_line_ids.length !== 0) {
        obj.move_line_ids.forEach((moveLine) => {
          let innerDeliveryData = {
            check_move: obj.check_move,
            is_move_checked: obj.checked,
            move_line_id: moveLine.id,
            finalize_picked: data?.finalize_picked,
            is_la_delivery: data?.is_la_delivery,
            product: moveLine.product_id?.display_name,
            create_delivery_package: data?.create_delivery_package,
            from: moveLine.location_id?.display_name,
            to: moveLine.location_dest_id?.display_name,
            blpName: moveLine.result_package_id?.name || 'N/A',
            checked: moveLine.checked,
            scan_picked: moveLine.scan_picked,
            reserve_quantity: moveLine.initial_reserve_qty,
            done_quantity: moveLine.done_qty,
            model: data.model,
            data: moveLine,
          };
          finalData.push(innerDeliveryData);
        });
      }
    });
  }
  return finalData;
};
