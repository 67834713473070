import React, { useEffect, useRef, useState } from 'react';
import { DetailBox, PopUpBox, Scanner, Table, TitleBar } from '../../common';
import {
  BUTTON,
  DELIVERY_OPERATIONS_COLUMN_CHECK,
  DELIVERY_LA_OPERATIONS_COLUMN,
  HEADING,
  POPUP_TYPE,
  ROUTES,
  TITLES,
  DELIVERY_DETAIL_OPERATION_COLUMN,
} from '../../../constants';
import { Button } from '../../shared';
import { ScanIcon } from '../../../assets/icons';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import {
  warehousePackageScan,
  warehouseDelivery,
  warehouseValidatePicking,
  warehouseTransferQtyUpdate,
  printPackingListDelivery,
  printUpsLabelDelivery,
  updateShopifyStatus,
  finalizePick,
  deliveryPickedQtyUpdateLA,
  deliveryStartPickingLA,
  warehouseCheckUncheckMove,
  warehouseCheckPicking,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { popup } from '../../../utils';
import {
  deliveryDetailBox,
  innerDeliveryDetailedOperationCompiler,
  innerDeliveryLAOperationsCompiler,
  innerDeliveryOperationsCompiler,
} from '../../../utils/data_compiler/deliveryDataCompiler';

export const DeliveryDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation(location.state);
  const [reload, setReload] = useState(false);
  const [stateData] = useState(state);
  const [deliveryData, setDeliveryData] = useState(stateData);
  const [data, setData] = useState(innerDeliveryDetailedOperationCompiler(deliveryData));
  const [activeTab, setActiveTab] = useState('operations');
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [showPopup, setShowPopup] = useState(0);
  const [popUpData, setPopUpData] = useState('');
  const disabledStates = ['draft', 'waiting', 'confirmed', 'cancel', 'done'];
  let columnsToFilterOut = ['Action', 'Status'];

  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  let buttonsDisabled = disabledStates.includes(deliveryData?.state);
  let filteredColumns;
  if (buttonsDisabled) {
    if (deliveryData?.state === 'draft') {
      columnsToFilterOut.push('Done Quantity');
    }
    if (deliveryData?.is_la_delivery && deliveryData?.is_start_picking_pressed === false) {
      columnsToFilterOut.push('Action', 'Status');
    }
    filteredColumns = DELIVERY_DETAIL_OPERATION_COLUMN.filter(
      (col) => !columnsToFilterOut.includes(col.name),
    );
  } else {
    filteredColumns = DELIVERY_DETAIL_OPERATION_COLUMN;
  }

  const enableScan = () => {
    for (let obj of data) {
      if (obj?.blpName !== 'N/A' && obj?.scan_picked === false) return true;
    }
    return false;
  };

  const enableConfirm = () => {
    for (let obj of data) {
      if (deliveryData?.is_check_picking === true) {
        if (obj.check_move && obj.is_move_checked === false) return false;
      }
      if (obj?.blpName !== 'N/A' && obj?.scan_picked === false && obj?.is_la_delivery === false)
        return false;
    }
    return true;
  };

  const startScanning = () => {
    setScanning((prev) => !prev);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    if (!enableScan() && scanning) {
      setScanning(false);
    }
  }, [data, scanning]);

  useEffect(() => {
    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      scanPackageAPI(scanData, true);
      setScanData('');
      getDeliveryList();
    }
  }, [scanData]);

  const scanPackageAPI = useDebounce((scanData, scanType) => {
    dispatch(setLoaderVisibility(true));
    warehousePackageScan({
      data: {
        package_name: scanData,
        id: deliveryData?.id,
        model: 'stock.picking',
        scan_type: scanType ? 'add' : 'remove',
      },
    })
      .then((response) => {
        popup(
          POPUP_TYPE.SUCCESS,
          scanType ? response.message : `Package ${scanData} unscanned successfully`,
        );
        getDeliveryList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const getDeliveryList = useDebounce(() => {
    warehouseDelivery({
      data: { picking_ids: deliveryData?.id, page_number: 1 },
    })
      .then((response) => {
        setReload(false);
        dispatch(setLoaderVisibility(false));
        setDeliveryData(response?.data?.records?.[0]);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleCheckPicking = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    warehouseCheckPicking({
      data: { picking_id: deliveryData.id, model: deliveryData.model },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        setReload(true);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getDeliveryList();
  }, []);

  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getDeliveryList();
    }
  }, [reload]);

  const validateOrder = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    warehouseValidatePicking({
      data: { picking_id: deliveryData.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.DELIVERIES);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleFinalizePick = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    finalizePick({
      data: { id: deliveryData?.id, model: deliveryData?.model },
    })
      .then((response) => {
        setReload(true);
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleStartPickingLA = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    deliveryStartPickingLA({
      data: { picking_id: deliveryData?.id },
    })
      .then((response) => {
        setReload(true);
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const updateQtyAPI = useDebounce((done_qty, move_line_id) => {
    dispatch(setLoaderVisibility(true));
    warehouseTransferQtyUpdate({
      data: {
        move_line_id: move_line_id,
        done_qty: parseFloat(done_qty),
      },
    })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        dispatch(setLoaderVisibility(false));
        getDeliveryList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const updatePickedQtyAPI = useDebounce((picked_qty, move_id) => {
    dispatch(setLoaderVisibility(true));
    deliveryPickedQtyUpdateLA({
      data: {
        move_id: move_id,
        quantity: parseFloat(picked_qty),
      },
    })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        dispatch(setLoaderVisibility(false));
        setReload(true);
      })
      .catch(() => {
        dispatch(setLoaderVisibility(false));
        // popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handlePrintPackingList = async () => {
    dispatch(setLoaderVisibility(true));
    printPackingListDelivery({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          window.open(response.data[0].split('?')[0], '_blank', 'noreferrer');
        } else {
          popup(POPUP_TYPE.ERR, response.message);
        }
      })
      .catch((err) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, err.message);
        throw err;
      });
  };
  const handleUpdateShopifyStatus = async () => {
    dispatch(setLoaderVisibility(true));
    updateShopifyStatus({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        dispatch(setLoaderVisibility(false));
        getDeliveryList();
      })
      .catch((err) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, err.message);
        throw err;
      });
  };
  const handlePrintUpsLabel = async () => {
    dispatch(setLoaderVisibility(true));
    printUpsLabelDelivery({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          window.open(response.data[0].split('?')[0], '_blank', 'noreferrer');
          getDeliveryList();
        } else {
          popup(POPUP_TYPE.ERR, response.message);
        }
      })
      .catch((err) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, err.message);
        throw err;
      });
  };

  useEffect(() => {
    setData(innerDeliveryDetailedOperationCompiler(deliveryData));
  }, [deliveryData]);

  useEffect(() => {
    if (
      ((deliveryData?.state === 'assigned' && deliveryData?.is_la_delivery === false) ||
        (deliveryData?.state === 'assigned' &&
          deliveryData?.is_la_delivery &&
          deliveryData?.is_start_picking_pressed &&
          deliveryData?.finalize_picked === false)) &&
      enableScan()
    ) {
      const handlePaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text').trim();
        if (pastedData) {
          scanPackageAPI(pastedData, true);
        }
      };
      window.addEventListener('paste', handlePaste);
      return () => {
        window.removeEventListener('paste', handlePaste);
      };
    }
  }, [scanPackageAPI]);

  useEffect(() => {
    if (!state) navigate(ROUTES.DELIVERIES);
  }, [state]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DELIVERY, route: ROUTES.DELIVERIES },
          { text: deliveryData?.name, route: ROUTES.DELIVERY_DETAIL },
        ]}
      />
      <div className="scroll-div">
        <div className="d-flex align-items-center justify-content-end">
          {deliveryData?.is_la_delivery && deliveryData?.state !== 'done' && (
            <div className="d-flex align-items-center justify-content-end">
              <Button
                clickHandler={handleStartPickingLA}
                className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                {BUTTON.START_PICKING}
              </Button>
            </div>
          )}
          {((deliveryData?.sale_id &&
            deliveryData?.state === 'assigned' &&
            !deliveryData?.finalize_picked &&
            deliveryData?.is_la_delivery === false) ||
            (deliveryData?.sale_id &&
              deliveryData?.state === 'assigned' &&
              !deliveryData?.finalize_picked &&
              deliveryData?.is_la_delivery &&
              deliveryData?.is_start_picking_pressed)) && (
            <div className="d-flex align-items-center justify-content-end">
              <Button
                clickHandler={handleFinalizePick}
                className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                {BUTTON.FINALIZE_PICK}
              </Button>
              {deliveryData?.is_check_picking &&
                !deliveryData?.is_picking_checked &&
                deliveryData?.state === 'assigned' && (
                  <Button
                    clickHandler={handleCheckPicking}
                    className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                    {BUTTON.STATUS_CHECK}
                  </Button>
                )}
            </div>
          )}
          {activeTab === 'detailOperation' || activeTab === 'operations' ? (
            <div className="d-flex justify-content-end">
              {deliveryData?.carrier_id?.delivery_type === 'ups' &&
                deliveryData?.generate_ups_label &&
                deliveryData?.carrier_tracking_ref === false && (
                  <Button
                    clickHandler={handlePrintUpsLabel}
                    className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                    {BUTTON.PRINT_UPS_LABEL}
                  </Button>
                )}
              {deliveryData?.is_shopify_delivery_order &&
                deliveryData?.picking_type_code === 'outgoing' &&
                deliveryData?.state === 'done' && (
                  <Button
                    clickHandler={handleUpdateShopifyStatus}
                    className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                    {BUTTON.UPDATE_SHOPIFY_STATUS}
                  </Button>
                )}
              {(((deliveryData?.state === 'done' || deliveryData?.state === 'assigned') &&
                deliveryData?.is_la_delivery === false) ||
                ((deliveryData?.state === 'done' || deliveryData?.state === 'assigned') &&
                  deliveryData?.is_la_delivery &&
                  deliveryData?.is_start_picking_pressed)) && (
                <Button
                  clickHandler={handlePrintPackingList}
                  className={`blue-btn d-flex align-items-center justify-content-center px-4 mx-2 mb-3`}>
                  {BUTTON.PRINT_PACKING_LIST}
                </Button>
              )}
              {((deliveryData?.state === 'assigned' && deliveryData?.is_la_delivery === false) ||
                (deliveryData?.state === 'assigned' &&
                  deliveryData?.is_la_delivery &&
                  deliveryData?.is_start_picking_pressed)) && (
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    clickHandler={validateOrder}
                    className={`blue-btn d-flex ${enableConfirm() ? '' : 'disabled'} ${deliveryData?.finalize_picked ? '' : 'disabled'} align-items-center justify-content-center px-4 mx-2 mb-3`}
                    disabled={!enableConfirm() || !deliveryData?.finalize_picked}>
                    {BUTTON.CONFIRM_DELIVERY}
                  </Button>
                </div>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
        <DetailBox colCount={2} colData={stateData} dataCompiler={deliveryDetailBox} />

        {/* Tab Navigation */}
        <div className="d-flex align-items-center tab-nav bg-white">
          <div
            className={`text-center w-50 flex-fill ${activeTab === 'operations' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('operations')}>
            <h4>{HEADING.OPERATIONS}</h4>
          </div>
          <div
            className={`text-center w-50 flex-fill ${activeTab === 'detailOperation' ? '' : 'active-nav-tab'}`}
            onClick={() => setActiveTab('detailOperation')}>
            <h4>{HEADING.DETAIL_OPERATIONS}</h4>
          </div>
        </div>

        {/* Tab Content */}
        {activeTab === 'operations' && (
          <div className="tab-content custom-table-content h-100">
            {state?.is_la_delivery === false && (
              <div className="my-3 h-100">
                <Table
                  customClass={true}
                  columns={DELIVERY_OPERATIONS_COLUMN_CHECK}
                  initialData={deliveryData}
                  setReload={setReload}
                  PAGE_SIZE={5}
                  colFilter={false}
                  compiler={{ use: true, tool: innerDeliveryOperationsCompiler }}
                />
              </div>
            )}
            {state?.is_la_delivery && (
              <div className="my-3 h-100">
                <Table
                  customClass={true}
                  columns={DELIVERY_LA_OPERATIONS_COLUMN}
                  initialData={deliveryData}
                  PAGE_SIZE={5}
                  colFilter={false}
                  compiler={{ use: true, tool: innerDeliveryLAOperationsCompiler }}
                  popup={showPopup}
                  setReload={setReload}
                  setPopUp={setShowPopup}
                  setPopUpData={setPopUpData}
                />
              </div>
            )}
            {showPopup === 3 && (
              <PopUpBox openPopUpBox={showPopup}>
                <QtyUpdateLA
                  apiCall={updatePickedQtyAPI}
                  setOpenConfirmationBox={setShowPopup}
                  data={popUpData}
                />
              </PopUpBox>
            )}
          </div>
        )}
        {activeTab === 'detailOperation' && (
          <div className="tab-content custom-table-content-1 h-100">
            {((deliveryData?.state === 'assigned' && deliveryData?.is_la_delivery === false) ||
              (deliveryData?.state === 'assigned' &&
                deliveryData?.is_la_delivery &&
                deliveryData?.is_start_picking_pressed &&
                deliveryData?.finalize_picked === false)) && (
              <div className="d-flex justify-content-end">
                {scanning && (
                  <Scanner
                    setScanData={setScanData}
                    isCamScannerOpen={isCamScannerOpen}
                    setIsCamScannerOpen={setIsCamScannerOpen}
                  />
                )}
                <Button
                  className={`white-btn ${enableScan() ? '' : 'disabled'} d-flex align-items-center justify-content-center px-4 mx-2`}
                  clickHandler={startScanning}
                  disabled={!enableScan()}>
                  {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX} <ScanIcon />
                </Button>
              </div>
            )}
            <div className="my-3 h-100">
              {
                <Table
                  customClass={true}
                  columns={filteredColumns}
                  initialData={data}
                  PAGE_SIZE={5}
                  to={null}
                  compiler={{ use: false }}
                  popup={showPopup}
                  setReload={setReload}
                  setPopUp={setShowPopup}
                  setPopUpData={setPopUpData}
                />
              }
              {showPopup === 1 && (
                <PopUpBox openPopUpBox={showPopup}>
                  <QtyUpdate
                    apiCall={updateQtyAPI}
                    setOpenConfirmationBox={setShowPopup}
                    data={popUpData}
                  />
                </PopUpBox>
              )}
              {showPopup === 2 && (
                <PopUpBox openPopUpBox={showPopup}>
                  <RemoveScan
                    apiCall={scanPackageAPI}
                    pickingId={deliveryData.id}
                    data={popUpData}
                    setOpenConfirmationBox={setShowPopup}
                  />
                </PopUpBox>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const QtyUpdate = ({ setOpenConfirmationBox, apiCall, data }) => {
  const [doneQty, setDoneQty] = useState('');
  const qtyRef = useRef();
  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(0);
    apiCall(doneQty, data.move_line_id);
  };

  useEffect(() => {
    qtyRef.current.focus();
  }, []);

  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 mt-3"> Enter Quantity </h4>
        <hr />
        <div className="w-100 modal-body py-0 d-flex flex-column  gap-3 justify-content-center align-items-center">
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={data?.reserve_quantity}
              ref={qtyRef}
              onChange={(e) => {
                setDoneQty(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

const QtyUpdateLA = ({ setOpenConfirmationBox, apiCall, data }) => {
  const [pickedQty, setPickedQty] = useState('');
  const qtyRef = useRef();
  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(0);
    apiCall(pickedQty, data.move_id);
  };

  useEffect(() => {
    qtyRef.current.focus();
  }, []);
  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 mt-3"> Enter Picked Quantity </h4>
        <hr />
        <div className="w-100 modal-body py-0 d-flex flex-column  gap-3 justify-content-center align-items-center">
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Picked Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={data?.picked_quantity}
              ref={qtyRef}
              onChange={(e) => {
                setPickedQty(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

const RemoveScan = ({ apiCall, setOpenConfirmationBox, data }) => {
  const closePopUp = () => {
    setOpenConfirmationBox(0);
  };
  const handleApiCall = () => {
    setOpenConfirmationBox(false);
    apiCall(data.blpName, !data.scan_picked);
  };
  return (
    <>
      <div className="d-flex m-1 ">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className=" text-center text-special mb-3 "> Un-Scan</h4>
          <p className="text-center text-special placeholder-color ">
            Are you sure to remove scan?
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CONFIRM}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};

export const CheckUncheckDelivery = (data) => {
  const dispatch = useDispatch();
  const handleCheckUncheck = () => {
    dispatch(setLoaderVisibility(true));
    warehouseCheckUncheckMove({
      data: {
        move_id: [data.move_id],
        checked: data.checked ? false : true,
      },
    })
      .then(() => {
        popup(POPUP_TYPE.SUCCESS, data.checked ? 'Status Unchecked' : 'Status Checked');
        data.setReload(true);
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleCheck = () => {
    handleCheckUncheck();
  };
  return (
    <input
      type="checkbox"
      className="checkbox"
      checked={data?.checked}
      onClick={handleCheck}
      disabled={!data.check_move}
      readOnly={!data.check_move}
    />
  );
};
