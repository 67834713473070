import React, { useEffect, useState } from 'react';
import { DropDown, PaginatedTable, SearchBar, TitleBar } from '../../common';
import { BUTTON, POPUP_TYPE, ROUTES, SHIPMENT_COLUMN, TITLES } from '../../../constants';
import { shipmentDataCompiler } from '../../../utils/data_compiler/shipmentCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { warehouseShipment } from '../../../service';
import { Button } from '../../shared';

export const Shipment = () => {
  const dispatch = useDispatch();
  const [shipmentData, setShipmentData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(SHIPMENT_COLUMN[6].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const fetchShipmentList = useDebounce((params = {}) => {
    dispatch(setLoaderVisibility(true));
    warehouseShipment({ data: params })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.records?.length > 0) {
          setShipmentData(response.data.records);
          setPaginationData(response.data.params);
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(response.data.state.map((item) => item.toUpperCase()));
          }
        } else {
          setShipmentData([]);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 100);

  const getShipmentList = (additionalParams = {}) => {
    dispatch(setLoaderVisibility(true));
    const params = {
      page_number: pageNumber,
      search: searchTerm
        ? {
            name: searchTerm,
            container_id: searchTerm,
          }
        : {},
      sort: sortingArray1.includes(sort1) ? { selection: { state: sort1.toLowerCase() } } : {},
      ...additionalParams,
    };
    fetchShipmentList(params);
  };

  useEffect(() => {
    if (search) {
      setPageNumber(1); // Reset page number on new search
      // setSearch(false);
      getShipmentList({ page_number: 1 });
    }
  }, [search]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getShipmentList();
  }, [pageNumber]);

  useEffect(() => {
    if (sortingArray1.length > 0) {
      setPageNumber(1); // Reset page number on sorting change
      getShipmentList({ page_number: 1 });
    }
  }, [sort1]);

  const resetFilter = () => {
    setSort1(SHIPMENT_COLUMN[6].name);
    setPageNumber(1);
    getShipmentList({ page_number: 1 });
  };

  useEffect(() => {
    if (!searchTerm && search) {
      setPageNumber(1);
      setSearch(false);
      getShipmentList({ page_number: 1 });
    }
  }, [searchTerm]);

  return (
    <>
      <TitleBar title={[{ text: TITLES.SHIPMENT, route: ROUTES.SHIPMENT }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar
          search={search}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          setSearch={setSearch}
          onDefaultSearch={getShipmentList}
        />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
          {sortingArray1.includes(sort1) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(shipmentData) && shipmentData?.length > 0 ? (
        <PaginatedTable
          columns={SHIPMENT_COLUMN}
          initialData={shipmentData}
          colFilter={false}
          to={ROUTES.SHIPMENT_DETAIL}
          compiler={{ use: true, tool: shipmentDataCompiler }}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          paginationData={paginationData}
        />
      ) : (
        <div>
          <h4 className="px-1 text-center py-5">No Records Found</h4>
        </div>
      )}
    </>
  );
};
