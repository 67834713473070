import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table, TitleBar } from '../../common';
import {
  TITLES,
  ROUTES,
  POPUP_TYPE,
  BUTTON,
  POPUP_MSG,
  RECEPTION_COLUMNS,
} from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from '../../../hooks';
import { createGrid, getPrint, receptionReport, startPelleting } from '../../../service';
import { setLoaderVisibility } from '../../../redux';
import { popup, receptionDataCompiler } from '../../../utils';
import { Button, Input } from '../../shared';
import { gridDataCreateUpdateConvertor } from './PalletingFunction';

export const PelletDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [create, setCreate] = useState(false);
  const [reload, setReload] = useState(false);
  const { state } = useLocation(location.state);
  const [receptionData, setReceptionData] = useState({});
  const [productData, setProductData] = useState([]);

  const handleGridCreation = () => {
    setCreate(true);
  };
  const getReceptionReport = useDebounce(() => {
    receptionReport({
      data: { shipment_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setReceptionData(response.data[0]);
        setReload(false);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);
  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getReceptionReport();
  }, []);
  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getReceptionReport();
    }
  }, [reload]);

  useEffect(() => {
    if (!state) navigate(ROUTES.PELLETING);
  }, [state]);

  useEffect(() => {
    if (receptionData.reception_report_ids?.length) {
      setProductData(
        receptionData.reception_report_ids.sort((a, b) => b.total_packages - a.total_packages),
      );
    }
  }, [receptionData]);

  const getPrintUrl = async () => {
    return getPrint({
      data: { picking_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          // popup(POPUP_TYPE.SUCCESS, response.message);
          return response.data[0].split('?')[0];
        } else {
          popup(POPUP_TYPE.ERR, response.message);
        }
      })
      .catch((err) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, err.message);
        throw err;
      });
  };

  const handlePrint = () => {
    const palletList = receptionData.pallet_ids.map((obj) => obj.id);
    dispatch(setLoaderVisibility(true));
    getPrintUrl({ palletIds: palletList })
      .then((url) => {
        window.open(url, '_blank', 'noreferrer');
      })
      .catch((err) => console.log(err));
  };
  const handleStartPelleting = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    if (receptionData.pallet_process_state === 'draft') {
      startPelleting({
        data: { shipment_id: state?.id },
      })
        .then((response) => {
          dispatch(setLoaderVisibility(false));
          if (Object.keys(response.data).length > 0) {
            const uniquePalletIds = response.data.pallets
              .map((item) => item.pallet_id.id)
              .filter((id, index, self) => self.indexOf(id) === index);
            if (uniquePalletIds.length) {
              return getPrintUrl({ palletIds: uniquePalletIds });
            }
          } else {
            popup(POPUP_TYPE.ERR, response.message);
          }
        })
        .then((url) => {
          if (url) {
            window.open(url, '_blank', 'noreferrer');
            let done = 'true';
            return done;
          }
        })
        .then((done) => {
          if (done) {
            navigate(`${ROUTES.PELLET_DETAILS}/${state?.name.replaceAll('/', '_')}`, {
              state: state,
            });
          }
        })
        .catch((error) => {
          dispatch(setLoaderVisibility(false));
          popup(POPUP_TYPE.ERR, error.message);
        });
    } else {
      navigate(`${ROUTES.PELLET_DETAILS}/${state?.name.replaceAll('/', '_')}`, { state: state });
    }
  }, 1000);
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.PELLETING, route: ROUTES.PELLETING },
          { text: TITLES.CONFIGURE, route: '' },
          { text: state?.container_id || state?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        {receptionData.pallet_process_state === 'done' || (
          <div className="d-flex justify-content-end gap-2 mb-3">
            {receptionData.pallet_ids?.length > 0 && (
              <Button
                clickHandler={handlePrint}
                className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
                {BUTTON.PRINT_QR}
              </Button>
            )}
            {receptionData?.grid_data?.rows > 0 && (
              <Button
                clickHandler={handleStartPelleting}
                className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
                {receptionData.pallet_process_state === 'draft'
                  ? BUTTON.START_PALLETING
                  : BUTTON.RESUME_PALLETING}
              </Button>
            )}
            <Button
              clickHandler={handleGridCreation}
              className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
              {receptionData?.grid_data?.rows !== 0 ? BUTTON.SHOW_GRID : BUTTON.GRID}
            </Button>
          </div>
        )}
        <ConfigurationPanel
          create={create}
          productData={productData}
          receptionData={receptionData}
          setCreate={setCreate}
          setReload={setReload}
          state={state}
        />
        <Table
          columns={RECEPTION_COLUMNS}
          initialData={productData}
          compiler={{ use: true, tool: receptionDataCompiler }}
          PAGE_SIZE={10}></Table>
      </div>
    </>
  );
};

const ConfigurationPanel = ({
  setReload,
  setCreate,
  receptionData,
  productData,
  create,
  state,
}) => {
  const [receiptDetail, setReceiptDetail] = useState({ boxCount: 0, requiredPallet: 0 });
  const [packagePalletType, setPackagePalletType] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pelletCount, setPelletCount] = useState(0);
  const [totalCount, seTotalCount] = useState(false);
  const [existingGridData, setExistingGridData] = useState(false);
  const [gridData, setGridData] = useState({
    rows: 0,
    pallets: [],
    pellets_count: 0,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(receptionData).length) {
      if (receptionData?.pallet_package_type_ids.length > 0) {
        setPackagePalletType(
          receptionData.pallet_package_type_ids.map((item) => {
            return {
              packageName: item?.package_type_id?.name || 'Unknown',
              palletsRequired: item?.pallets_required || 0,
              totalPackage: item?.total_packages || 0,
              initialPalletCount: item?.initial_pallets_required || 0,
            };
          }),
        );
      }
    }
  }, [receptionData]);
  const handleRowInput = (value) => {
    if (value < 0) {
      setRowCount('');
    } else if (value <= pelletCount && value - Math.floor(value) === 0) {
      setRowCount(value);
      setGridData({
        rows: +value,
        pellets_count: +pelletCount,
        pallets: Array.from({ length: value }, (_, index) => {
          return { rowIndex: +index, pallet: 0 };
        }),
      });
    }
  };

  const handlePelletCountOnRow = (e, rowIndex) => {
    setGridData((pre) => {
      return {
        ...pre,
        pallets: pre.pallets?.map((a) => {
          return a.rowIndex === rowIndex
            ? {
                ...a,
                pallet: +e.target.value,
              }
            : a;
        }),
      };
    });
  };

  const totalPallet = gridData.pallets?.reduce((acc, cum) => {
    acc += +cum.pallet;
    return acc;
  }, 0);
  const handleReset = (value) => {
    if (value < 0) {
      setPelletCount('');
    } else if (value <= receiptDetail.requiredPallet && value - Math.floor(value) === 0) {
      setPelletCount(value);
      setRowCount(0);
      setGridData({ rows: 0, pallets: [], pellets_count: value });
    }
  };

  const handleSubmit = () => {
    dispatch(setLoaderVisibility(true));
    if (
      gridData.rows > 0 &&
      gridData.pellets_count > 0 &&
      gridData.pallets.every((obj) => obj.pallet > 0)
    ) {
      if (
        ((rowCount && rowCount <= receiptDetail.requiredPallet) ||
          (pelletCount && pelletCount <= receiptDetail.requiredPallet)) &&
        totalPallet == pelletCount
      ) {
        createGrid({
          data: {
            shipment_id: receptionData.id,
            grid_data: gridDataCreateUpdateConvertor(gridData, receptionData.grid_data),
          },
        })
          .then(
            (response) => popup(POPUP_TYPE.SUCCESS, response.message),
            dispatch(setLoaderVisibility(false)),
            setReload(true),
            setGridData({
              rows: 0,
              pallets: [],
              pellets_count: 0,
            }),
            setRowCount(0),
            setPelletCount(0),
          )
          .catch((error) => {
            dispatch(setLoaderVisibility(false));
            popup(POPUP_TYPE.ERR, error.message);
          });
        setCreate(false);
      } else {
        popup(POPUP_TYPE.ERR, POPUP_MSG.WRONG_INPUT);
        dispatch(setLoaderVisibility(false));
      }
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.FILL_ALL_ROWS);
      dispatch(setLoaderVisibility(false));
    }
  };
  const handleCancel = () => {
    setRowCount(0);
    setPelletCount(0);
    setGridData({
      rows: 0,
      pallets: [],
      pellets_count: 0,
    });
    setCreate(false);
  };

  useEffect(() => {
    setReceiptDetail({
      boxCount: productData.reduce((acc, cum) => (acc += cum.total_packages), 0),
      requiredPallet:
        receptionData.remaining_pallet > 0
          ? receptionData.remaining_pallet
          : productData.reduce((acc, cum) => (acc += cum.pallets_required), 0),
    });
  }, [productData]);
  useEffect(() => {
    seTotalCount(totalPallet == gridData.pellets_count);
  }, [gridData, pelletCount, rowCount]);

  useEffect(() => {
    if (receptionData.grid_data) {
      setExistingGridData(receptionData.grid_data);
    }
  }, [receptionData]);

  return (
    <div className="bg-white top-data-box  py-3 px-4">
      <div className="row">
        <div className=" col-12">
          <div className="d-flex row mt-2">
            {/* Static Data */}
            <div className="col-8 ">
              <div className="row ">
                <div className="col-5 mb-4">Box Count:</div>
                <div className="col-3 mb-4">{receiptDetail.boxCount}</div>
              </div>
              <div className="row ">
                <div className="col-5 mb-4">Required Pallets:</div>
                <div className="col-3 mb-4">{receiptDetail.requiredPallet}</div>
              </div>
              <div className="row ">
                <div className="col-5 mb-4">Shipment Number:</div>
                <div className="col-3 mb-4">{state?.container_id || 'N/A'}</div>
              </div>
              <div className="row ">
                <div className="col-5 mb-4">Shipment Reference:</div>
                <div className="col-3 mb-4">{state?.name}</div>
              </div>
            </div>

            {/* Dynamic Data */}
            <div className="col-4">
              {packagePalletType?.length > 0 &&
                packagePalletType.map((item, index) => (
                  <div key={index} className="row ">
                    <div className="col-8 mb-1">{`${item.packageName} Pallets:`}</div>
                    <div className=" text-end col-3 mb-1">{`${item.initialPalletCount}`}</div>
                    <div className="col-8 mb-1">{`${item.packageName} Package:`}</div>
                    <div className=" text-end col-3 mb-1">{item.totalPackage}</div>
                  </div>
                ))}
            </div>
          </div>

          {create && (
            <div>
              <hr />
              <div className="d-flex justify-content-between align-items-center gap-3 mt-2">
                <div className="d-flex w-50 align-items-center gap-2 ">
                  <h5>Pallets Count :</h5>
                  <Input
                    type="number"
                    placeholder={
                      Math.min(existingGridData?.pallets_count,receiptDetail?.requiredPallet)
                    }
                    className={`${pelletCount && pelletCount <= receiptDetail.requiredPallet ? ' border-success-subtle' : 'border-danger-subtle'} border  border-2 w-50 ps-2 d-flex align-items-center justify-content-center`}
                    value={pelletCount || ''}
                    onChange={(e) => handleReset(+e.target.value)}
                  />
                </div>
                <div className="d-flex w-50 align-items-center gap-2">
                  <h5 className="ms-1">Row Count :</h5>
                  <Input
                    placeholder={existingGridData?.rows || `Max Limit ${pelletCount}`}
                    type="number"
                    className={`${rowCount && rowCount <= receiptDetail.requiredPallet ? ' border-success-subtle' : 'border-danger-subtle'} border  border-2 w-50 ps-2 d-flex align-items-center justify-content-center`}
                    value={rowCount || ''}
                    onChange={(e) => handleRowInput(+e.target.value)}
                  />
                </div>
              </div>
              <hr />
              {(rowCount && rowCount <= pelletCount) || receptionData.grid_data ? (
                <div className="multi-row-input w-100">
                  <h6 className="w-100 text-center">Enter Pallet Count for each Row</h6>
                  <h6 className="w-100 d-flex justify-content-end">
                    {rowCount ? `${totalPallet}/${pelletCount}` : ''}
                  </h6>
                  <div className="w-100 d-flex justify-content-start flex-wrap">
                    {Array.from({ length: rowCount || existingGridData?.pallets?.length }).map(
                      (a, index) => (
                        <input
                          key={index}
                          placeholder={
                            (existingGridData && existingGridData?.pallets[index]?.pallet) ||
                            `Row-${index + 1}`
                          }
                          type="number"
                          className={`${
                            totalCount ? 'border-success-subtle' : 'border-danger-subtle'
                          } border  m-2 col-xl-2 col-2 text-center`}
                          onChange={(e) => {
                            handlePelletCountOnRow(e, index);
                          }}
                        />
                      ),
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
              <hr />
              <div className="d-flex justify-content-around">
                <Button
                  clickHandler={handleSubmit}
                  className="blue-btn d-flex align-items-center justify-content-center px-4 py-2">
                  {receptionData.grid_data ? BUTTON.UPDATE : BUTTON.SUBMIT}
                </Button>
                <Button
                  clickHandler={handleCancel}
                  className="blue-btn d-flex align-items-center justify-content-center px-4 py-2">
                  {BUTTON.CANCEL}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
