import React, { useEffect, useState } from 'react';
import { ScanIcon } from '../../../assets/icons';
import { Scanner } from '../../common';
import { popup } from '../../../utils';
import { POPUP_MSG, POPUP_TYPE } from '../../../constants';
import { packagePlacing, createGrid } from '../../../service';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { Button } from '../../shared';
import { gridDataConvertor } from './PalletingFunction';

export const DetailsBoxPellet = ({
  doneStatus,
  scanData,
  setScanData,
  pelletData,
  packageData,
  selectedPellet,
  setReload,
  count,
  gridData,
  packageScanned,
  setSelectedPellet,
  setPackageScanned,
  popUp,
  state,
  setGridData,
  setPrevScanData
}) => {
  const placed = selectedPellet?.packages?.map((pack) => pack.blp).includes(scanData);
  const [scanState, setScanState] = useState(false);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const dispatch = useDispatch();

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  const handleScanStart = () => {
    setScanState((pre) => !pre);
    setScanData('');
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  const handleTransfer = () => {
    if (packageScanned.length) {
      const pack = packageData.filter((pack) =>
        packageScanned.map((scanPack) => scanPack.package).includes(pack?.package_id?.name),
      );

      if (pack.length && pack.every((pck) => pck.state === 'assigned')) {
        dispatch(setLoaderVisibility(true));
        packagePlacing({
          data: {
            pallet_process_ids: pack.map((pck) => pck.id),
            type: 'placed',
          },
        })
          .then((response) => {
            createGrid({
              data: {
                shipment_id: state?.id,
                grid_data: gridDataConvertor(gridData), // Send the entire updated gridData here
              },
            })
              .then(() => {
                setReload(true);
                setPrevScanData('');
                popup(
                  POPUP_TYPE.SUCCESS,
                  POPUP_MSG.PACKAGE_PLACED(
                    packageScanned.length < 2
                    ? packageScanned[0].package
                    : `${packageScanned.length} package`,
                  response.data[0].state,
                  selectedPellet.tile.display_name,
                  ),
                );
                setSelectedPellet({});
              })
              .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup(POPUP_TYPE.ERR, error.message);
              });
          })
          .catch((error) => {
            dispatch(setLoaderVisibility(false));
            popup(POPUP_TYPE.ERR, error.message);
          });
      } else if (pack[0]?.state === 'placed') {
        popup(POPUP_TYPE.ERR, POPUP_MSG.ALREADY_PLACED);
        setSelectedPellet({});
      } else {
        popup(POPUP_TYPE.ERR, POPUP_MSG.PLACED_AND_VERIFIED);
        setSelectedPellet({});
      }

      setScanState(true);
      setScanData('');
      setPackageScanned('');
      setIsCamScannerOpen(false);
    }
  };
      

  useEffect(() => {
    if (popUp) {
      setScanState(false);
      setIsCamScannerOpen(false);
    }
  }, [popUp]);

  useEffect(() => {
    if (Object.entries(selectedPellet).length) {
      setGridData((pre) => {
        return {
          ...pre,
          pallets: pre.pallets.map((pal, index) => {
            return {
              ...pal,
              list: pal.list.map((lst, ind) => {
                return selectedPellet.tile.rowIndex === index &&
                  selectedPellet.tile.palletIndex === ind
                  ? { pallet_id: selectedPellet.pellet_id, pallet_name: selectedPellet.pellet_name }
                  : { ...lst };
              }),
            };
          }),
        };
      });
    }
  }, [selectedPellet]);


  useEffect(() => {
    isCameraScanBool && !isCamScannerOpen ? setScanState(false) : '';
  })

  return (
    <div className="bg-white top-data-box mb-3 py-3 px-4">
      <div className="row">
        <div className="col-xl-5 col-12">
          {(!doneStatus || count < pelletData.packageCount) && (
            <div className="d-flex align-items-center justify-content-start  ">
              <div className="btn-set-pellet h-50 flex-column d-flex align-items-center  me-5">
                <div
                  onClick={handleScanStart}
                  className="white-btn w-100 d-flex align-items-center justify-content-center px-4 py-2">
                  {scanState ? 'Stop Scan' : 'Scan Box'}
                  <ScanIcon />
                </div>
                {Object.keys(selectedPellet).length > 0 ? (
                  <Button
                    clickHandler={handleTransfer}
                    disabled={Object.keys(selectedPellet).length === 0}
                    className={`${!Object.keys(selectedPellet)?.length && 'disabled'}  blue-btn w-100 d-flex align-items-center justify-content-center px-4 py-2`}>
                    {selectedPellet ? 'Confirm Transfer' : 'Scan Again'}
                  </Button>
                ) : (
                  ''
                )}
              </div>
              {scanState ? <Scanner setScanData={setScanData} isCamScannerOpen={isCamScannerOpen} setIsCamScannerOpen={setIsCamScannerOpen}/> : ''}

              {Object.keys(selectedPellet).length > 0 && (
                <div className="d-flex align-items-center">
                  <InfoPellet selectedPellet={selectedPellet} placed={placed} packageScanned={packageScanned} />
                  <h4 className="m-3 text-success">&quot;{selectedPellet?.SKU}&quot;</h4>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const InfoPellet = ({ selectedPellet, placed,packageScanned }) => {
  return (
    <>
      {selectedPellet ? (
        <div className="d-flex flex-column align-items-center justify-content-center ">
          <div className="pellet-color-box flex-column h-50 d-flex align-items-center ">
            <div className={`box-top h-25 ${placed ? 'highlighted' : 'blue-box'}`}>
              <h4 className="text-center pt-2 ">
                {selectedPellet.tile.display_name}
                <sup className="ps-2  text-black">
                  {packageScanned.length > 1 ? packageScanned.length : ''}
                </sup>
              </h4>
            </div>
            <div
              className={`box-bottom h-25 pellet-info ${placed ? 'highlighted-bottom' : 'dark-blue'}  d-flex align-items-center justify-content-center`}>
              <div className="text-center">
                {`${selectedPellet?.packages?.length}/ ${selectedPellet.packages_count}`}
              </div>
            </div>
          </div>
        </div>
      ) : (
        'Wrong BLP Scanned'
      )}
    </>
  );
};
