import { HEADERS, LOCAL_STORAGE, POPUP_TYPE } from '../constants';
import { getLocalStorageItem } from './localStorage';
import { popup } from './popup';

const myHeaders = new Headers();
myHeaders.append('Content-Type', `${HEADERS.APPLICATION_JSON}`);

export const fetchData = async ({ body, url, method }) => {
  // eslint-disable-next-line no-undef
  const apiUrl = process.env.REACT_APP_API_URL + url;
  if (navigator.onLine) {
    try {
      const authToken = getLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN);
      if (authToken) {
        myHeaders.set('API-KEY', `${authToken}`);
      }
      const requestBody = JSON.stringify({
        jsonrpc: '2.0',
        method: 'call',
        params: body,
      });

      const response = await fetch(apiUrl, {
        body: requestBody,
        method: method,
        headers: myHeaders,
      });

      if (response) {
        const json = await response.json();
        if (json.status === 'pass') {
          return json.result;
        } else if (json.status === 'fail' && json.message === 'AccessDenied') {
          localStorage.clear();
          window.location.pathname = '';
        } else if (json?.error && json?.error?.data && json?.error?.data?.message) {
          popup(POPUP_TYPE.ERR, json?.error?.data?.message);
        }
      }
    } catch (error) {
      throw new Error('Internal server error');
    }
  } else {
    popup(POPUP_TYPE.ERR, 'Please check your Internet connection');
  }
};
